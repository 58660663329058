"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctionFactorEiq = exports.getUnits = void 0;
var constants_1 = require("./constants");
/**
 * @function getUnits
 * @description Function that returns a list of supported units
 * @return array
 */
var getUnits = function () { return constants_1.defaultUnits; };
exports.getUnits = getUnits;
/**
 * @function correctionFactorEiq
 * @description function that applies a correction coefficient to the EIQ according to the unit
 * @param eiq Product eiq
 * @param unit Unit in which the surface and the total applied of Eiq are expressed
 * @return value
 */
var correctionFactorEiq = function (eiq, unit) {
    if (!constants_1.defaultUnits.includes(unit.toLowerCase())) {
        throw new Error("the unit does not belong to those allowed: [".concat(constants_1.defaultUnits.join(' ,'), "]"));
    }
    // System Metric Volume
    if (constants_1.litersUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.COEFFICIENT_LITERS;
    }
    // System Metric Mass
    if (constants_1.kilogramsUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.COEFFICIENT_KILOGRAMS;
    }
    if (constants_1.tonsUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.COEFFICIENT_TONS;
    }
    // System Imperial Volume
    if (constants_1.gallonsUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.GALLON_IN_PINTS_EQUIVALENCE;
    }
    if (constants_1.quartUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.QUART_IN_PINTS_EQUIVALENCE;
    }
    // System Imperial Mass
    if (constants_1.ounceUnits.includes(unit.toLowerCase())) {
        return eiq / constants_1.LIBRA_IN_OUNCE_EQUIVALENCE;
    }
    if (constants_1.tonsShortUnits.includes(unit.toLowerCase())) {
        return eiq * constants_1.TONS_SHORT_IN_LIBRA_EQUIVALENCE;
    }
    if (constants_1.fluidOunceUnits.includes(unit.toLowerCase())) {
        return eiq / constants_1.PINT_IN_FLUID_OUNCE_EQUIVALENCE;
    }
    // Default Pint(pt) || Libra(lb)
    return eiq;
};
exports.correctionFactorEiq = correctionFactorEiq;
