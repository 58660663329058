"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEIQRange = exports.ERangesEiq = void 0;
var utils_1 = require("./utils");
var ERangesEiq;
(function (ERangesEiq) {
    ERangesEiq["VERY_HIGH"] = "VERY_HIGH";
    ERangesEiq["HIGH"] = "HIGH";
    ERangesEiq["MODERATE"] = "MODERATE";
    ERangesEiq["LOW"] = "LOW";
    ERangesEiq["VERY_LOW"] = "VERY_LOW";
})(ERangesEiq = exports.ERangesEiq || (exports.ERangesEiq = {}));
/**
 * @function getEiqRange
 * @description get description string of range for EIQ
 * @param {Number} eiq
 * @returns range
 */
var getEIQRange = function (eiq) {
    if (!(0, utils_1.isNumber)(eiq)) {
        throw new Error('The value of the EIQ must be numeric');
    }
    var rangeDescription = ERangesEiq.VERY_HIGH;
    if ((0, utils_1.isInRange)(eiq, 0, 25)) {
        rangeDescription = ERangesEiq.VERY_LOW;
    }
    if ((0, utils_1.isInRange)(eiq, 25, 50)) {
        rangeDescription = ERangesEiq.LOW;
    }
    if ((0, utils_1.isInRange)(eiq, 50, 100)) {
        rangeDescription = ERangesEiq.MODERATE;
    }
    if ((0, utils_1.isInRange)(eiq, 100, 150)) {
        rangeDescription = ERangesEiq.HIGH;
    }
    if ((0, utils_1.isInRange)(eiq, 150, 200)) {
        rangeDescription = ERangesEiq.VERY_HIGH;
    }
    return rangeDescription;
};
exports.getEIQRange = getEIQRange;
