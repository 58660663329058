"use strict";
// UTILS
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInRange = exports.parseDecimals = exports.divide = exports.isInvalidDenominator = exports.isNumber = exports.sum = exports.multiply = exports.validator = void 0;
var validator = function (values) {
    Object.keys(values).forEach(function (key) {
        if (!(0, exports.isNumber)(values[key])) {
            throw new Error('Input data must be numeric');
        }
    });
};
exports.validator = validator;
var multiply = function (a, b) { return a * b; };
exports.multiply = multiply;
var sum = function (current, value) { return current + value; };
exports.sum = sum;
var isNumber = function (value) { return !isNaN(Number(value)); };
exports.isNumber = isNumber;
var isInvalidDenominator = function (denominator) { return [NaN, 0].includes(Number(denominator)); };
exports.isInvalidDenominator = isInvalidDenominator;
var divide = function (numerator, denominator) {
    if ((0, exports.isInvalidDenominator)(denominator))
        throw new Error('the value of the numerator in a division must be greater than or equal to 0');
    return numerator / denominator;
};
exports.divide = divide;
var parseDecimals = function (value, decimal) {
    if (decimal) {
        if (!(0, exports.isNumber)(decimal)) {
            throw new Error('The value of the decimal must be numeric');
        }
        return parseFloat(value.toFixed(decimal));
    }
    return value;
};
exports.parseDecimals = parseDecimals;
var isInRange = function (value, min, max) {
    if (!(0, exports.isNumber)(value) || !(0, exports.isNumber)(min) || !(0, exports.isNumber)(max)) {
        throw new Error('All values must be numeric');
    }
    return value >= min && value <= max;
};
exports.isInRange = isInRange;
