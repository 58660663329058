"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateEIQWithList = exports.calculateEIQ = exports.factorEIQDosageProduct = exports.calculateDosage = void 0;
var utils_1 = require("./utils");
var managerUnits_1 = require("./managerUnits");
/**
 * @function calculateDosage
 * @description function that receives total used area and surface and retrieve the dosage to use
 * @param total Total used for the selected area
 * @param surface net area to which the eiq dose is applied
 * @return value
 */
var calculateDosage = function (total, surface) {
    (0, utils_1.validator)({ total: total, surface: surface });
    return (0, utils_1.divide)(total, surface);
};
exports.calculateDosage = calculateDosage;
/**
 * @function factorEiqDosageProduct
 * @description function that receives two parameters as an argument and returns its product
 * @param eiq Product eiq
 * @param quantity total eiq used in a given area. Result obtained with CalculateDosage
 * @return value
 */
var factorEIQDosageProduct = function (eiq, quantity) {
    (0, utils_1.validator)({ eiq: eiq, quantity: quantity });
    return (0, utils_1.multiply)(eiq, quantity);
};
exports.factorEIQDosageProduct = factorEIQDosageProduct;
/**
 * @function calculateEiq
 * @description function that calculate the EIQ used in one area
 * @param surface net area to which the eiq dose is applied
 * @param eiq Product eiq
 * @param total Total used for the selected area
 * @param unit Unit in which the surface and the total applied of Eiq are expressed
 * @param [decimal] number to fixed final result
 * @return value
 */
var calculateEIQ = function (surface, eiq, total, unit, decimal) {
    (0, utils_1.validator)({ surface: surface, eiq: eiq, total: total });
    return (0, utils_1.parseDecimals)((0, exports.factorEIQDosageProduct)((0, managerUnits_1.correctionFactorEiq)(eiq, unit), (0, exports.calculateDosage)(total, surface)), decimal);
};
exports.calculateEIQ = calculateEIQ;
/**
 * @function calculateEiqWithList
 * @description function that calculate the EIQ used in one area with multiple EIQ products
 * @param surface Total cultivation area
 * @param eiqList Arrangement of inputs applied to the crop
 * @param [decimal] number to fixed final result
 * @return value
 */
var calculateEIQWithList = function (surface, eiqList, decimal) {
    return (0, utils_1.parseDecimals)(eiqList.map(function (_a) {
        var eiq = _a.eiq, total = _a.total, unit = _a.unit;
        return (0, exports.calculateEIQ)(surface, eiq, total, unit);
    }).reduce(utils_1.sum, 0), decimal);
};
exports.calculateEIQWithList = calculateEIQWithList;
