"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultUnits = exports.tonsShortUnits = exports.ounceUnits = exports.libraUnits = exports.fluidOunceUnits = exports.quartUnits = exports.gallonsUnits = exports.pintsUnits = exports.tonsUnits = exports.kilogramsUnits = exports.litersUnits = void 0;
// System Metric
exports.litersUnits = ['lt', 'lt/ha', 'lts', 'lts/ha'];
exports.kilogramsUnits = ['kg', 'kg/ha', 'kgs', 'kgs/ha'];
exports.tonsUnits = ['tn', 'tn/ha'];
// System Imperial
exports.pintsUnits = ['pt', 'pt/ac'];
exports.gallonsUnits = ['gal', 'gal/ac'];
exports.quartUnits = ['qt', 'qt/ac'];
exports.fluidOunceUnits = ['fl-oz', 'fl-oz/ac'];
exports.libraUnits = ['lb', 'lb/ac'];
exports.ounceUnits = ['oz', 'oz/ac'];
exports.tonsShortUnits = ['t', 't/ac'];
exports.defaultUnits = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], exports.litersUnits, true), exports.kilogramsUnits, true), exports.tonsUnits, true), exports.pintsUnits, true), exports.gallonsUnits, true), exports.quartUnits, true), exports.fluidOunceUnits, true), exports.libraUnits, true), exports.ounceUnits, true), exports.tonsShortUnits, true);
