var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable curly */
import React, { useEffect, useRef } from 'react';
import { Platform, } from 'react-native';
/**
 * If you are using a `ScrollView` or `FlatList` in ActionSheet. You must attach `scrollHandlers`
 * with it to enable vertical scrolling. For horizontal ScrollViews, you should not use this hook.
 * @param id Id for the handler. Could be any string value.
 * @param ref ref of the ActionSheet in which the ScrollView is present.
 * @param minGesutureBoundary The minimum area of scrollView from top, where swipe gestures are allowed always.
 * @returns
 */
export function useScrollHandlers(id, ref, minGesutureBoundary) {
    if (minGesutureBoundary === void 0) { minGesutureBoundary = 50; }
    var timer = useRef();
    var scrollRef = useRef(null);
    var scrollLayout = useRef();
    var scrollOffset = useRef(0);
    var prevState = useRef(false);
    var subscription = useRef();
    var onScroll = function (event) {
        var _a;
        scrollOffset.current = event.nativeEvent.contentOffset.y;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.modifyGesturesForLayout(id, scrollLayout.current, scrollOffset.current);
    };
    var disableScrolling = React.useCallback(function () {
        if (Platform.OS === 'web') {
            //@ts-ignore
            scrollRef.current.style.touchAction = 'none';
            //@ts-ignore
            scrollRef.current.style.overflowY = 'hidden';
        }
    }, [scrollRef]);
    var enableScrolling = React.useCallback(function () {
        if (Platform.OS === 'web') {
            //@ts-ignore
            scrollRef.current.style.overflowY = 'scroll';
            //@ts-ignore
            scrollRef.current.style.touchAction = 'auto';
        }
    }, [scrollRef]);
    useEffect(function () {
        return function () {
            var _a;
            (_a = subscription.current) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        };
    }, [id, ref, disableScrolling, enableScrolling]);
    var onMeasure = function (_x, _y, w, h, px, py) {
        var _a;
        scrollLayout.current = {
            width: w,
            height: h,
            x: px,
            y: py
        };
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.modifyGesturesForLayout(id, scrollLayout.current, scrollOffset.current);
    };
    function measureAndLayout() {
        clearTimeout(timer.current);
        timer.current = setTimeout(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            //@ts-ignore support flatlist
            if ((_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a._listRef) {
                //@ts-ignore
                (_d = (_c = (_b = scrollRef.current._listRef) === null || _b === void 0 ? void 0 : _b._scrollRef) === null || _c === void 0 ? void 0 : _c.measure) === null || _d === void 0 ? void 0 : _d.call(_c, onMeasure);
            } //@ts-ignore support flash-list.
            else if ((_e = scrollRef.current) === null || _e === void 0 ? void 0 : _e.rlvRef) {
                //@ts-ignore support flash-list.
                (_j = (_h = (_g = (_f = scrollRef.current) === null || _f === void 0 ? void 0 : _f.rlvRef) === null || _g === void 0 ? void 0 : _g._scrollComponent) === null || _h === void 0 ? void 0 : _h._scrollViewRef) === null || _j === void 0 ? void 0 : _j.measure(onMeasure);
            }
            else {
                //@ts-ignore regular scrollView
                (_l = (_k = scrollRef.current) === null || _k === void 0 ? void 0 : _k.measure) === null || _l === void 0 ? void 0 : _l.call(_k, onMeasure);
            }
        }, 500);
    }
    var onLayout = function (event) {
        var _a;
        scrollLayout.current = __assign(__assign({}, event.nativeEvent.layout), { y: event.nativeEvent.layout.y || minGesutureBoundary });
        measureAndLayout();
        subscription.current = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.ev.subscribe('onoffsetchange', function (offset) {
            measureAndLayout();
            if (offset < 3) {
                if (prevState.current)
                    return;
                prevState.current = true;
                enableScrolling();
            }
            else {
                if (!prevState.current)
                    return;
                prevState.current = false;
                disableScrolling();
            }
        });
    };
    return {
        scrollEnabled: true,
        onScroll: onScroll,
        ref: scrollRef,
        onLayout: onLayout,
        scrollEventThrottle: 50
    };
}
