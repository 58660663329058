"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PINT_IN_FLUID_OUNCE_EQUIVALENCE = exports.QUART_IN_PINTS_EQUIVALENCE = exports.GALLON_IN_PINTS_EQUIVALENCE = exports.TONS_SHORT_IN_LIBRA_EQUIVALENCE = exports.LIBRA_IN_OUNCE_EQUIVALENCE = exports.COEFFICIENT_LITERS = exports.COEFFICIENT_TONS = exports.COEFFICIENT_KILOGRAMS = void 0;
// System Metric Mass
exports.COEFFICIENT_KILOGRAMS = 0.892179;
exports.COEFFICIENT_TONS = 892.1788493;
// System Metric Volume
exports.COEFFICIENT_LITERS = 0.855253;
// System Imperial Mass
exports.LIBRA_IN_OUNCE_EQUIVALENCE = 16; // oz
exports.TONS_SHORT_IN_LIBRA_EQUIVALENCE = 2000; // lb
// System Imperial Volume
exports.GALLON_IN_PINTS_EQUIVALENCE = 8; // pt
exports.QUART_IN_PINTS_EQUIVALENCE = 2; // pt
exports.PINT_IN_FLUID_OUNCE_EQUIVALENCE = 16; // fl-oz
